<template>
  <v-dialog :model-value="true" max-width="350" @click:outside="close">
    <v-card>
      <v-card-title class="headline"> {{ action }} Scope </v-card-title>

      <v-card-text>
        Are you sure you want to {{ action.toLowerCase() }} the {{ scope }} scope?
      </v-card-text>

      <sw-card-actions
        :cancel-disabled="isSubmitting"
        :submit-loading="isSubmitting"
        :submit-text="action"
        @click:submit="modifyScope"
        @click:cancel="close"
      ></sw-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
  import SwCardActions from "sweepatic-shared/components/SwCardActions.vue";
  import { computed, ref } from "vue";

  import { useStore } from "@/composables/store";
  import { reportSuccess, reportUnexpectedError } from "@/EventBus";

  const $store = useStore();

  const $emit = defineEmits<{
    (e: "click:close", modified: boolean): void;
  }>();

  const props = defineProps({
    scope: { type: String, required: true },
    status: { type: Boolean, required: true },
  });

  const isSubmitting = ref<boolean>(false);

  const action = computed<string>(() => {
    return props.status ? "Enable" : "Disable";
  });

  const toggleScopeStatus = (payload: Object) =>
    $store.dispatch("scopes/toggleScopeStatus", payload);

  const close = (modified: boolean = false) => {
    $emit("click:close", modified);
  };

  const modifyScope = async () => {
    isSubmitting.value = true;

    try {
      await toggleScopeStatus({ scope: props.scope, payload: { disabled: !props.status } });
      reportSuccess(`Scope ${props.status ? "enabled" : "disabled"}`);
      close(true);
    } catch (error: any) {
      reportUnexpectedError(error, `Scope could not be ${props.status ? "enabled" : "disabled"}`);
    }

    isSubmitting.value = false;
  };
</script>

<style></style>
