export interface NewScope {
  name: string;
  seeds: string[];
  partner: boolean;
}

export interface CheckWildcardReponse {
  wildcard: boolean;
  domain: string;
}

export interface GetLightDomainsRequest {
  domain: string;
  countOnly: boolean;
}

export interface ScopeRole {
  scope: string;
  role: string;
}

export interface Access {
  scope: string;
  subscope?: string;
  role: string;
}

export interface ScopeUser {
  email: string;
  username: string;
  fullname: string;
  access: Access[];
}

export interface Permision {
  context: string;
  access_type: string;
  name: string;
  key: string;
}

export interface Role {
  id: string;
  name: string;
  scope: string | null;
  permissions: Record<string, Permision[]>;
}

export interface UpdateScopeModule {
  name: string;
  partner_name: string;
  module: ScopeModule;
  upgrade?: UpgradePayload;
}

export interface ScopeModule {
  enabled: boolean | null;
  licensed: boolean;
  config?: ModuleConfig;
  created?: Date;
}

export interface ScopeModuleWithName extends ScopeModule {
  name: string;
}

export type ModuleConfig = CredentialsConfig | OutscanConfig;

export interface CredentialsConfig {
  band?: string;
}

export interface OutscanConfig {
  credentials: {
    username?: string;
    password?: string;
  };
}

export enum ModuleType {
  files = "files", // read-only
  easm = "easm",
  credentials = "credentials",
  outscan = "outscan",
}

export type ScopeModuleConfig = Partial<Record<ModuleType, ScopeModule>>;

export type LicenseDuration = "month" | "year";

export type PartnerContractType = "head_count_based" | "asset_size_based" | "flat";

export type UpgradePayload = {
  license_duration: LicenseDuration;
  contract_type: PartnerContractType;
  partner_name: string;
  head_count?: number;
  target_partner?: string;
};

export interface Seed {
  domain: string;
  isSeed: boolean;
  totalSubdomains: number;
  error?: string;
  warning?: string;
  modules?: string[];
  credentials?: boolean;
  easm?: boolean;
  outscan?: boolean;
}

export type Bands = Record<string, Band>;

export type Band = {
  certificate: number;
  domain: number;
  ip_range: number;
  key: string;
  name: string;
  subdomain: number;
  term: number;
  website: number;
};
